import React from "react"
import { Link } from "gatsby"
import { Row, Col, Card } from "react-bootstrap"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
// import { FormEventAnalytics } from "../../utils/analytics"
import { setUTMSource } from "../../utils/storage"
import scrollTo from "gatsby-plugin-smoothscroll"

class SIS extends React.Component {
  componentDidMount() {
    setUTMSource()
  }

  render() {
    return (
      <Layout>
        <SEO
          title={"Student Information System"}
          titleDesc={
            " Manage all academic and student records with easy accessibility through AI-powered Student Information System by Fuse Classroom."
          }
          titleKeywords={
            "sis, sis system, student information system ,student management system, AI-powered Student Information System, sis software, best student management system"
          }
          fbDes={" Manage your student information through Fuse Classroom’s Student Information System (SIS)."}
          twitDesc={"Manage your student information through Fuse Classroom’s Student Information System (SIS)."}
          seoLink={"platform/student-information-system/"}
        />

        {/* top section */}
        <section role="top--section" className="bg-light-green overflow-hidden">
          {/* content */}
          <article className="container">
            <Row className="align-items-center py-4">
              <Col md="6" className="pt-md-5 pb-5">
                <div className="text-green">
                  <h1>Student Information System (SIS)</h1>
                  <div className="h4 mt-4 text-black font-weight-normal">
                    AI-powered Student Information System tracks academic and student information in one centralized
                    location. See the features below for a full list of information tracked by the SIS.
                  </div>

                  <div className="main-cta mt-4">
                    <Link
                      to="/request-for-more-info/"
                      className="btn btn-success mr-3 btn-lg font-weight-semibold"
                      // onClick={() => FormEventAnalytics("SIS_Top_Request_Demo")}
                      state={{ page: "SIS" }}
                      id="request--for-more-info__top"
                    >
                      REQUEST FOR MORE INFO
                    </Link>
                  </div>
                </div>
              </Col>

              <Col md={6}>
                <div className="fluid-left d-none d-md-block mt-3 mb-3">
                  <img src="/images/platform/sis/sis.webp" alt="Student Information System" width="640" height="auto" />
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* top section end */}

        {/* SIS */}
        <section className="pb-5 d-none d-md-block" id="ai-info">
          <article className="container ai-features">
            <Row>
              <Col sm="12" className="py-4 mb-5 text-center">
                <div className="my-2 text-center title">SIS</div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col lg="2" md="6" xs="4" className="my-3">
                <button onClick={() => scrollTo("#Student-360-View")} className="platform-ai">
                  <Card>
                    <Card.Body>
                      <div className="ai-image">
                        <img src="/images/icon/360-view.svg" alt="Student 360 View" width="45" height="auto" />
                      </div>
                      <Card.Title className="ai-title">Student 360 View</Card.Title>
                      <div className="small">
                        Student 360 View ensures no student goes unnoticed and intervention is possible at any stage in
                        the student learning cycle.
                      </div>
                    </Card.Body>
                    <Card.Footer>
                      <img
                        src="/images/implementation/chevron-down.svg"
                        className="img-fluid"
                        width="13"
                        height="auto"
                      />
                    </Card.Footer>
                  </Card>
                </button>
              </Col>

              <Col lg="2" md="6" xs="4" className="my-3">
                <button onClick={() => scrollTo("#Degree-Audit")} className="platform-ai">
                  <Card>
                    <Card.Body>
                      <div className="ai-image">
                        <img src="/images/icon/degree-audit.svg" alt="Degree Audit" width="45" height="auto" />
                      </div>
                      <Card.Title className="ai-title">Degree Audit</Card.Title>
                      <div className="small">
                        Track a student’s progres to make sure they fulfill all the requirements of their degree.
                      </div>
                    </Card.Body>
                    <Card.Footer>
                      <img
                        src="/images/implementation/chevron-down.svg"
                        className="img-fluid"
                        width="13"
                        height="auto"
                      />
                    </Card.Footer>
                  </Card>
                </button>
              </Col>

              <Col lg="2" md="6" xs="4" className="my-3">
                <button onClick={() => scrollTo("#Transcripts")} className="platform-ai">
                  <Card>
                    <Card.Body>
                      <div className="ai-image">
                        <img
                          src="/images/icon/transcripts.svg"
                          alt="Mobile App"
                          className="img-fluid"
                          width="45"
                          height="auto"
                        />
                      </div>
                      <Card.Title className="ai-title">Transcripts</Card.Title>
                      <div className="small">Access unofficial transcripts and request formal transcripts.</div>
                    </Card.Body>
                    <Card.Footer>
                      <img
                        src="/images/implementation/chevron-down.svg"
                        className="img-fluid"
                        width="13"
                        height="auto"
                      />
                    </Card.Footer>
                  </Card>
                </button>
              </Col>

              <Col lg="2" md="6" xs="4" className="my-3">
                <button onClick={() => scrollTo("#Digital-Badges-Awards")} className="platform-ai">
                  <Card>
                    <Card.Body>
                      <div className="ai-image">
                        <img
                          src="/images/icon/awards.svg"
                          alt="Digital Badges &amp; Awards"
                          className="img-fluid"
                          width="45"
                          height="auto"
                        />
                      </div>
                      <Card.Title className="ai-title">Digital Badges &amp; Awards</Card.Title>
                      <div className="small">Celebrate student accomplishment with digital badges and awards.</div>
                    </Card.Body>
                    <Card.Footer>
                      <img
                        src="/images/implementation/chevron-down.svg"
                        className="img-fluid"
                        width="13"
                        height="auto"
                      />
                    </Card.Footer>
                  </Card>
                </button>
              </Col>

              <Col lg="2" md="6" xs="4" className="my-3">
                <button onClick={() => scrollTo("#Fees")} className="platform-ai">
                  <Card>
                    <Card.Body>
                      <div className="ai-image">
                        <img src="/images/icon/fees.svg" alt="Fees" width="45" height="auto" />
                      </div>
                      <Card.Title className="ai-title">Fees</Card.Title>
                      <div className="small">Centralize invoices for all classes.</div>
                    </Card.Body>
                    <Card.Footer>
                      <img
                        src="/images/implementation/chevron-down.svg"
                        className="img-fluid"
                        width="13"
                        height="auto"
                      />
                    </Card.Footer>
                  </Card>
                </button>
              </Col>
            </Row>
          </article>
        </section>
        {/* SIS End */}

        {/* Student 360 View*/}
        <section id="Student-360-View" className="py-5">
          <article className="container py-2">
            <Row className="align-items-center">
              <Col md={{ span: 6, offset: 1, order: 2 }}>
                <img
                  src="/images/platform/sis/360.webp"
                  alt="Student 360 View"
                  className="img-fluid"
                  width="640"
                  height="auto"
                />
              </Col>
              <Col md={{ span: 5, order: 1 }}>
                <div className="my-4">
                  <div className="mb-2">
                    <img src="/images/icon/360-view.svg" alt="icon" width="45" height="auto" />
                  </div>
                  <h2 className="my-4">Student 360 View</h2>
                  <div className="h5 text-black font-weight-normal">
                    Instructors and administrators have on demand access to student data, visualizations of student
                    performance, and engagement with the platform. With the 360 view and student status engine, no
                    student goes unnoticed so that intervention is possible at any stage in the student learning cycle.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* Student 360 View end */}

        {/* Degree Audit */}
        <section id="Degree-Audit" className="py-5 bg-80 left">
          <article className="container">
            <Row className="align-items-center">
              <Col md={{ span: 6 }}>
                <img
                  src="/images/platform/sis/degree-audit.webp"
                  alt="Degree Audit"
                  className="img-fluid"
                  width="640"
                  height="auto"
                />
              </Col>
              <Col md={{ span: 5, offset: 1 }}>
                <div className="my-4">
                  <div className="mb-2">
                    <img src="/images/icon/degree-audit.svg" alt="Degree Audit" width="45" height="auto" />
                  </div>
                  <h2 className="my-4">Degree Audit</h2>
                  <div className="h5 text-black font-weight-normal">
                    Available to students, instructors, and administrators, the degree audit feature provides a
                    dashboard showing students’ progress fulfilling the requirements of their degree. Degree audit
                    ensures student self accountability.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* Online Live */}

        {/* Transcripts */}
        <section id="Transcripts" className="py-5">
          <article className="container">
            <Row className="align-items-center">
              <Col md={{ span: 6, offset: 1, order: 2 }}>
                <img
                  src="/images/platform/sis/transcript.webp"
                  alt="Transcripts"
                  className="img-fluid"
                  width="640"
                  height="auto"
                />
              </Col>
              <Col md={{ span: 5, order: 1 }}>
                <div className="my-4">
                  <div className="mb-2">
                    <img src="/images/icon/transcripts.svg" alt="icon" width="45" height="auto" />
                  </div>
                  <h2 className="my-4">Transcripts</h2>
                  <div className="h5 text-black font-weight-normal">
                    Within the Transcripts page, students can download unofficial transcripts and request formal
                    transcripts from the registrar.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* Community end) */}

        {/* Digital Badges &amp; Awards */}
        <section className="py-5 bg-80 left" id="Digital-Badges-Awards">
          <article className="container">
            <Row className="align-items-center">
              <Col md={{ span: 6 }}>
                <img
                  src="/images/platform/sis/badges.webp"
                  alt="Digital Badges &amp; Awards"
                  className="img-fluid"
                  width="640"
                  height="auto"
                />
              </Col>
              <Col md={{ span: 5, offset: 1 }}>
                <div className="my-4">
                  <div className="mb-2">
                    <img src="/images/icon/awards.svg" alt="icon" width="45" height="auto" />
                  </div>
                  <h2 className="my-4">Digital Badges &amp; Awards</h2>
                  <div className="h5 text-black font-weight-normal">
                    As students progress in their studies, instructors and administrators can award them with a digital
                    badge or award to commend their efforts. Many badges and awards are available so student effort can
                    be celebrated regardless of the nature of the accomplishment.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* Digital Badges &amp; Awards end */}

        {/* Fees */}
        <section id="Fees" className="py-5">
          <article className="container">
            <Row className="align-items-center">
              <Col md={{ span: 6, offset: 1, order: 2 }}>
                <img src="/images/platform/sis/fees.webp" alt="Fees" className="img-fluid" width="640" height="auto" />
              </Col>
              <Col md={{ span: 5, order: 1 }}>
                <div className="my-4">
                  <div className="mb-2">
                    <img src="/images/icon/fees.svg" alt="icon" width="45" height="auto" />
                  </div>
                  <h2 className="my-4">Fees</h2>
                  <div className="h5 text-black font-weight-normal">
                    The Fees page makes it easier than ever to centralize invoicing. Students can send payments through
                    the platform as well.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* Fees end) */}

        {/* call to action */}
        <section className="py-5">
          <article className="container">
            <Row className="justify-content-center">
              <Col lg="10">
                <div className="rounded border border-success p-4 text-center">
                  <span className="d-inline-block mr-md-5 mb-0 h4 font-weight-bold">
                    Interested in more information about our platform?
                  </span>
                  {/* <button className="btn btn-success" onClick={() => this.setState({modalShow: true})}>Request a Demo</button> */}
                  <Link
                    to="/request-for-more-info/"
                    className="btn btn-success mt-3 mt-xl-0"
                    // onClick={() => FormEventAnalytics("SIS_Bottom_Request_Demo")}
                    state={{ page: "SIS" }}
                    id="request--for-more-info__bottom"
                  >
                    REQUEST FOR MORE INFO
                  </Link>
                </div>
              </Col>
            </Row>
          </article>
        </section>
      </Layout>
    )
  }
}

export default SIS
